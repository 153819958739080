<template>
    <div id="Instructions"> 
      <body class="">
	  
	  
<!-- header -->
    <nav class="navbar fixed-top nicole-noshadow">
      <div class="container">
        <a @click="onBack" class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" >
          <img class="avatar avatar-sm" src="assets/img/icon-back-white.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>      
<!-- End header -->
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
      <div class="carousel-inner vh-100">
        <div class="carousel-item active">
            <div class="vh-100 position-relative">
                <div class="bg-gradient-success vh-60"></div>
                <div class="nicole-member-center">
                    <div class="phone mx-auto">
                    <img src="assets/img/img01.png" class="w-100">
                    </div>
                    <div class="text-center text-dark text-card-title  contextbox">
                        一機在手，行動學習<br>海量文章、多元主題<br>英文閱讀、聽力能力一把抓<br>整個世界都是我的行動英文教室
                    </div>
                </div>
            </div>
             <ol class="carousel-indicators ">
                 <div class="text-success text-sm text-bold py-1 px-3 rounded-pill mb-2"> 01/09</div>
              </ol>
        </div>
        <div class="carousel-item ">
            <div class="vh-100 position-relative">
                <div class="bg-gradient-success vh-60"></div>
                <div class="nicole-member-center">
                    <div class="phone mx-auto">
                    <img src="assets/img/img02.png" class="w-100">
                    </div>
                    <div class="text-center text-dark text-card-title contextbox">
                        &nbsp;<br>分段學習，靈活運用零碎時間<br>從文章朗讀、名師講解到課後練習<br>能力培養一應俱全，學習不卡關
                    </div>
                </div>
            </div>
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 02/09</div>
              </ol>
        </div>
        <div class="carousel-item">
            <div class="vh-100 position-relative">
                <div class="bg-gradient-success vh-60"></div>
                <div class="nicole-member-center">
                    <div class="phone mx-auto">
                    <img src="assets/img/img03.png" class="w-100">
                    </div>
                    <div class="text-center text-dark text-card-title contextbox">
                         &nbsp;<br>名師講解，深入淺出<br>文章剖析、重點整理，精準到位<br>專人解決疑難雜症，學習有效率
                    </div>
                </div>
            </div>
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2 "> 03/09</div>
              </ol>
        </div>       
          <div class="carousel-item">
            <div class="vh-100 position-relative">
                <div class="bg-gradient-success vh-60"></div>
                <div class="nicole-member-center">
                    <div class="phone mx-auto">
                    <img src="assets/img/img04.png" class="w-100">
                    </div>
                    <div class="text-center text-dark text-card-title contextbox">
                        客製化專屬學習課程<br>自選等級與主題，學習更符合需求<br>學習狀態一覽無遺，自我檢視更確實
                    </div>
                </div>
            </div>                
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 04/09</div>
              </ol>
        </div>          
          <div class="carousel-item">
            <div class="vh-100 pic5">
            </div>             
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2 shadow"> 05/09</div>
              </ol>
        </div>          
          <div class="carousel-item">
            <div class="vh-100 pic6">
            </div>   
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 06/09</div>
              </ol>
        </div>          
          <div class="carousel-item">
            <div class="vh-100 pic7">
            </div>   
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 07/09</div>
              </ol>
        </div>          
          <div class="carousel-item">
            <div class="vh-100 pic8">
            </div>   
             <ol class="carousel-indicators">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 08/09</div>
              </ol>
        </div>          
          <div class="carousel-item">
            <div class="vh-100 pic9">
            </div>   
             <ol class="carousel-indicators" @click="onBack">
                 <div class="text-success text-sm text-bold py-1 px-3 bg-white rounded-pill mb-2"> 09/09</div>
              </ol>
        </div>
      </div>
    
      <a class="carousel-control-prev w-20" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next w-20" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>  
	
	</body>
    </div>
</template>


<script>

export default {
    name: 'Instructions',
    components: {
    },
    data () {
    return { 
        }
    },
    methods: {
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){    
				this.$router.push(
				{
					name : 'Setting',
					params : {   
					}
				});
		  
        },
		
    },
    mounted() {
    },
    created(){
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }
      this.$nextTick(() => { 
          $("#carouselExampleIndicators").carousel(0);
      });  
	
    }
}
</script>